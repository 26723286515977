import { CanActivate, Router } from "@angular/router";
import { Injectable } from "@angular/core";

@Injectable()
export class DashboardGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate() {
    if (window.localStorage.getItem("auth_app_token")) return true;
    else {
      this.router.navigateByUrl("/auth/login");
      return false;
    }
  }
}
