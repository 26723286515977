import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class OrdersService {
  base_url = environment.base_url;

  constructor(private httpClient: HttpClient) {}

  //   getSettings() {
  //     return this.httpClient.get(`${this.base_url}admin/settings?paginate=0`);
  //   }

  //   updateSettings(id, payload) {
  //     return this.httpClient.put(`${this.base_url}admin/settings/${id}`, payload);
  //   }

  getUsterInfo() {
    return this.httpClient.get(`${this.base_url}auth/user/info`);
  }

  getOrderByType(type: string) {
    return this.httpClient.get(
      `${this.base_url}${
        JSON.parse(window.localStorage.getItem("userInfo")).type
      }/orders?type=${type}&paginate=0`
    );
  }

  getDeliveryOrdersByDate() {
    return this.httpClient.get(
      `${this.base_url}${
        JSON.parse(window.localStorage.getItem("userInfo")).type
      }/ordersByDate?paginate=0`
    );
  }

  getDeliveryByDate(date) {
    return this.httpClient.get(
      `${this.base_url}${
        JSON.parse(window.localStorage.getItem("userInfo")).type
      }/ordersByDate/specific-date?date=${date}&paginate=0`
    );
  }

  updateWithdrawStatus(order_id, payload) {
    return this.httpClient.put(
      `${this.base_url}admin/update/withdraw/status/${order_id}`,
      payload
    );
  }

  updateDepositStatus(order_id, payload) {
    return this.httpClient.put(
      `${this.base_url}admin/update/deposit/status/${order_id}`,
      payload
    );
  }

  getDeleteRequests() {
    return this.httpClient.get(
      `${this.base_url}admin/approve-delete-request?paginate=0`
    );
  }

  approveDeleteRequest(order_id, payload) {
    return this.httpClient.put(
      `${this.base_url}admin/approve-delete-request/${order_id}`,
      payload
    );
  }

  getGifts() {
    return this.httpClient.get(`${this.base_url}admin/gifts?paginate=0`);
  }

  updateDeliveryStatus(id, payload) {
    return this.httpClient.put(
      `${this.base_url}admin/update/deposit/status/${id}`,
      payload
    );
  }

  approveSingleOrder(payload) {
    return this.httpClient.post(
      `${this.base_url}${
        JSON.parse(window.localStorage.getItem("userInfo")).type
      }/is_approved/order`,
      payload
    );
  }

  approveAllOrderInDate(payload) {
    return this.httpClient.post(
      `${this.base_url}${
        JSON.parse(window.localStorage.getItem("userInfo")).type
      }/is_approved/order/date`,
      payload
    );
  }

  updateOrderDelivery(id, payload) {
    return this.httpClient.put(
      `${this.base_url}admin/update/order/delivery/status/${id}`,
      payload
    );
  }
}
