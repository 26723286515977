import { of as observableOf, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Contacts, RecentUsers } from "../data/users";

@Injectable()
export class UserService {
  private time: Date = new Date();

  private users = {
    nick: { name: "Admin", picture: "assets/images/nick.png" },
  };
  private types = {
    mobile: "mobile",
    home: "home",
    work: "work",
  };
  private contacts: Contacts[] = [
    { user: this.users.nick, type: this.types.mobile },
  ];

  getUsers(): Observable<any> {
    return observableOf(this.users);
  }

  getContacts(): Observable<Contacts[]> {
    return observableOf(this.contacts);
  }
}
